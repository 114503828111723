import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgTourMap from './assets/tour-map-2024.jpg';

const googleFormUrlPrefix = 'https://docs.google.com/forms/d/e/1FAIpQLSfCGdDmvOzn0DRLo55k_6bN8nj6S1icle3jpUj1lfdM8nauBg/viewform?usp=pp_url&entry.9960490=';

const tUrl = (str) => {
  return googleFormUrlPrefix + str.replace(/ /g, '+');
}

const googleMyMapsUrl = "https://www.google.com/maps/d/edit?mid=1GmHNAz_Q5kpEEMFTxNFGM8WwZN201OE&usp=sharing";
const wsjUrl = "https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc?st=0fg615evonrtpm1&reflink=desktopwebshare_permalink";

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const TourDivStyled = styled.div`
  font-size: 40px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
  text-align: center;

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
      background-color: #5755c3;
      border-color: #5755c3;
      width: 200px;
      height: 50px;
      border-radius: 30px;
      font-weight: bold;
  }
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`;



export const Tour = () => {
  return (
    <Styles>
      <CustomJumbotron bgimage={ImgHeader}>Tour</CustomJumbotron>
      <Container style = {{paddingTop: 30, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

        <h2>
          Talks (mostly on ChatGPT's impact on math, jobs, and education)
        </h2>

        <PStyled>
          Curious why people are talking about these events? Read <a href={wsjUrl}>The Wall Street Journal's review of the talk.</a>
        </PStyled>

        <PStyled>
          Want Prof. Loh to speak to your whole school and its parent
          community? There is no cost for public schools, thanks to private
          schools which customarily provide an honorarium for invited
          Assembly speakers. <a
          href="https://www.roxburylatin.org/2024/01/24/dr-po-shen-loh-combines-ai-education-and-art/">Typical impact of talk.</a> To book, email <a
              href="mailto:hello@poshenloh.com">hello@poshenloh.com</a>.
        </PStyled>

        <PStyled>
          Scroll down for registration links. <a href={googleMyMapsUrl}>
            Click on map for scrollable, zoomable map</a>, with clickable
            map markers that say which date Prof. Loh will be there.
        </PStyled>

        <a href={googleMyMapsUrl}>
          <img src={ImgTourMap} alt="2023 Summer Tour Stop Map" style={{maxWidth: "100%"}} />
        </a>

        <PStyled>
          In the summer of <Link to="/tour2021">2021</Link>, Prof. Loh
          conducted the largest outdoor solo math tour in human history,
          holding events in public parks. They were so successful that he
          continued touring in <Link to="/tour2022">2022</Link>, and again
            in <Link to="/tour2023">2023</Link> when he gave 100+ talks in
          60+ cities over the summer. His current topic guides parents and
          students on how to dramatically adjust educational approaches in
          the era of ChatGPT. These fun events also inspire students to see
          math from a different angle and to embrace challenge. 
        </PStyled>

        <Row className="align-items-center" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <Col xs={12} lg={6}>
            <Video>
              <iframe
                title="live-iframe"
                style={{position: "absolute", top: 0, 
                  left: 0, width: "100%", height: "100%"}}
                src="https://www.youtube.com/embed/3sgCAXWLdYk"
                frameBorder="0"
                allowFullScreen
              />
            </Video>
          </Col>

          <Col xs={12} lg={6}>
            <PStyled>
              This tour is supported by <a
                href="https://live.poshenloh.com">LIVE</a>, the <a
                  href="https://www.cnn.com/world/professor-po-shen-loh-actors-classrooms-spc">new
                  online math school</a> that Prof. Loh founded, which
              revolutionizes live-video math instruction: teaching students
              the art of invention.
            </PStyled>

            <TourDivStyled>
              <a href="https://live.poshenloh.com">
                <Button variant="primary">
                  Learn LIVE
                </Button>
              </a>
            </TourDivStyled>
          </Col>
        </Row>

        <h2>
          Event Schedule (Jul&ndash;Aug 2024)
        </h2>

        <PStyled>
          Individual registration links to all upcoming tour stops are
          listed below. Register directly via the corresponding link if a
          time and location fit you. If you will miss (or already missed)
          Prof. Loh's visit to your area, you can also sign up to be
          notified whenever he comes next time. If the link does not go to
          a registration page, that particular event is not open to the
          public.
        </PStyled>

        <TourDivStyled>
          <a href="https://forms.gle/Qu2EoVBxtVAYmKHa6">
            <Button variant="primary">
              Notify me
            </Button>
          </a>
        </TourDivStyled>

        <OLStyled>
          <li><a href="https://www.eventbrite.com/e/914719299047">Cleveland, OH: Thu Jul 25 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914726861667">Detroit, MI: Fri Jul 26 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/949088087017">Toronto: Sat Jul 27 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914731736247">Toronto: Sat Jul 27 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914741565647">Buffalo, NY: Sun Jul 28 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914746069117">Rochester, NY: Sun Jul 28 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914747964787">Syracuse, NY: Mon Jul 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914748797277">Ottawa: Tue Jul 30 at 7:00pm</a></li>
          <li><a href="https://www.hertzfoundation.org/event/2024-summer-workshop/">Mont-Tremblant, Canada (Hertz Foundation Summer Workshop, co-organizer): Aug 1&ndash;4</a></li>
          <li><a href="https://www.eventbrite.com/e/965359214397">Montreal: Sun Aug 4 at 3:00pm (in Chinese)</a></li>
          <li><a href="https://www.eventbrite.com/e/914751074087">Montreal: Sun Aug 4 at 7:00pm (in English)</a></li>
          <li><a href="https://www.eventbrite.com/e/914752678887">Westchester, NY: Mon Aug 5 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914754253597">Philadelphia, PA: Tue Aug 6 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914755998817">Fairfax County, VA: Wed Aug 7 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914757272627">Durham, NC: Thu Aug 8 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914757924577">Charlotte, NC: Fri Aug 9 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914762879397">Atlanta, GA (Norcross): Sat Aug 10 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914763431047">Atlanta, GA (Alpharetta): Sat Aug 10 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914764744977">Birmingham, AL: Sun Aug 11 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/914768807127">Huntsville, AL: Sun Aug 11 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914770151147">Nashville, TN (Murfreesboro): Mon Aug 12 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914780863187">Louisville, KY: Tue Aug 13 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914779920367">Indianapolis, IN: Wed Aug 14 at 7:00pm</a></li>
          <li><a href="https://summit.newturing.ai/">Ho Chi Minh City, Vietnam (New Turing Institute GenAI Summit): Aug 16&ndash;19</a></li>
          <li><a href="https://www.eventbrite.com/e/914781454957">Cincinnati, OH: Wed Aug 21 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/914782437897">Columbus, OH: Thu Aug 22 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/909876504117">San Francisco, CA (Dublin): Fri Aug 23 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/999616107687">Sacramento, CA (Roseville): Sat Aug 24 at 1:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/991410995977">San Francisco, CA (Cupertino): Sat Aug 24 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/998777258667">San Francisco, CA (Milpitas): Sun Aug 25 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/998780989827">San Francisco, CA (Milpitas): Sun Aug 25 at 3:00pm</a></li>
          <li><a href={tUrl("CA Irvine")}>Irvine, CA: Mon Aug 26</a></li>
          <li>Syosset, NY (Syosset Central School District Superintendent's Conference Day): Tue Aug 27 at 9:00am</li>
        </OLStyled>

        <h2>
          Fall 2024
        </h2>

        <OLStyled>
          <li><a href={tUrl("OK Oklahoma City")}>Oklahoma City, OK: Sat Sep 14</a></li>
          <li><a href="https://www.cmu.edu/mscf">New York, NY (Carnegie Mellon University Master of Science in Computational Finance alumni event): Mon Sep 16</a></li>
          <li><a href="https://fpnetwork.org/event-5763945">Miami, FL (Florida Philanthropic Network Miami Math Meeting): Tue Sep 17</a></li>
          <li><a href="https://www.heidelberg-laureate-forum.org">Heidelberg, Germany (Heidelberg Laureate Forum): Tue Sep 24</a></li>
          <li><a href="https://summermathprograms.org/conference.html">Atlanta, GA (Summer Mathematics Programs Consortium Conference): Sat Sep 21</a></li>
          <li><a href="https://www.nuevaschool.org/about/conferences-and-community-programs/math-circle">San Francisco, CA (Nueva Math Circle): Sat Oct 5</a></li>
          <li><a href="https://mathprize.atfoundation.org">Boston, MA (Math Prize for Girls): Sun Oct 6</a></li>
          <li><a href="https://www.mphschool.org">Syracuse, NY (Manlius Pebble Hill School): Mon Oct 7</a></li>
          <li><a href="https://www.sbs.org">Long Island, NY (Stony Brook School): Mon Oct 21</a></li>
          <li><a href="https://www.heliosschool.org">San Francisco, CA (Helios School): Mon Oct 28</a></li>
          <li><a href="https://www.sjs.org">Houston, TX (St. John's School): Mon Nov 4</a></li>
          <li><a href="https://www.ucfsd.org">Westchester, NY (Unionville-Chadds Ford School District): Mon Nov 11</a></li>
          <li>Dallas, TX (Association of Chinese Professionals Annual MetroCon): Sat Nov 16</li>
        </OLStyled>

        <h2>
          Previous Events in 2024
        </h2>

        <OLStyled>
          <li><a href="https://iit2024.org/">Washington, DC (IIT2024 Global Conference): Sat Jan 13 at 3:10pm</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Jan 21 at 6:30pm</a></li>
          <li><a href="https://www.roxburylatin.org/2024/01/24/dr-po-shen-loh-combines-ai-education-and-art/">Boston, MA (Roxbury Latin School): Tue Jan 23</a></li>
          <li>Houston, TX (St. John's School): Thu Jan 25</li>
          <li><a href="https://www.caltechmathmeet.org/">Pasadena, CA (Caltech Math Meet): Sat Jan 27 at 9:15am</a></li>
          <li><a href="https://mathm.org/">New York, NY (MATHCOUNTS Manhattan): Sat Feb 3</a></li>
          <li>Boston, MA (Sharon): Thu Feb 8</li>
          <li><a href="http://institutecreativeproblemsolving.org/">Long Island, NY (Institute of Creative Problem Solving): Sat Feb 10 at 11:00am</a></li>
          <li>Miami, FL (American Heritage School): Tue Feb 13</li>
          <li><a href="https://www.hmmt.org/">Boston, MA (Harvard&ndash;MIT Math Tournament): Sat Feb 17</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Feb 25 at 6:30pm</a></li>
          <li><a href="https://www.humancapabilityinitiative.org/">Riyadh, Saudi Arabia (Human Capability Initiative): Wed Feb 28</a></li>
          <li>Beijing (Beijing Number 4 High School 北京四中): Fri Mar 1 at 6:30pm</li>
          <li>Beijing (Yale Beijing Center): Sat Mar 2 at 7:00pm</li>
          <li>Shanghai: Sun Mar 3 at 10:30am</li>
          <li>Shanghai (The Little Bridge Experimental Primary School 筑桥实验小学): Sun Mar 3 at 3:00pm</li>
          <li>Shanghai (Shanghai American School): Mon Mar 4 at 11:00am</li>
          <li>Nanjing (BASIS International School): Mon Mar 4 at 4:30pm</li>
          <li>Nanjing (Nanjing Foreign Language School 南京外国语学校): Mon Mar 4 at 7:30pm</li>
          <li>Suzhou (Suzhou Foreign Language School 苏州外国语学校): Tue Mar 5 at 6:00pm</li>
          <li>Shenzhen: Wed Mar 6 at 4:30pm</li>
          <li>Shenzhen (Futian BASIS International School): Thu Mar 7 at 5:30pm</li>
          <li>Hong Kong City University: Fri Mar 8 at 10:30am</li>
          <li>Hong Kong (MIT Hong Kong Innovation Node): Fri Mar 8 at 5:30pm</li>
          <li>Hong Kong Baptist University Affiliated School: Sat Mar 9 at 10:00am</li>
          <li>Hong Kong (CTF Education Group): Sat Mar 9 at 3:00pm</li>
          <li>Hong Kong (Bloom KKCA Academy): Sun Mar 10 at 10:30am</li>
          <li>Hong Kong: Sun Mar 10 at 2:30pm</li>
          <li><a href="https://www.hrpolicy.org/events/chro-summit/">Orlando, FL (HR Policy Association Annual CHRO Summit): Thu Mar 14</a></li>
          <li><a href="https://form.jotform.com/240655374041149">Boston, MA (Sharon): Sat Mar 16 at 10:15am</a></li>
          <li><a href="https://iswonline.org/event-5599214">Boston, MA (Indian Institutes of Technology Association of Greater New England and India Society of Worcester): Sat Mar 16 at 1:00pm</a></li>
          <li><a href="https://form.jotform.com/240667412593157">Boston, MA (Brookline): Sat Mar 16 at 5:00pm</a></li>
          <li><a href="https://momath.org/unlimited/">New York, NY (National Museum of Mathematics): Sun Mar 17 at 6:00pm</a></li>
          <li><a href="https://form.jotform.com/240746320999163">Philadelphia, PA: Sat Mar 23 at 10:30am</a></li>
          <li><a href="http://sections.maa.org/newjersey/Main/index.html">Gloucester County, NJ (MAA NJ Section meeting): Sat Mar 23 at 3:45pm</a></li>
          <li><a href="https://form.jotform.com/240295028559159">New York, NY (Great Neck): Sun Mar 24 at 3:00pm</a></li>
          <li><a href="https://ranchosolano.com/special-event-with-dr-po-shen-loh/">Phoenix, AZ (Rancho Solano School): Tue Mar 26 at 6:30pm</a></li>
          <li><a href="https://cmimc.math.cmu.edu/">Pittsburgh, PA (Carnegie Mellon Informatics and Mathematics Competition): Sat Apr 6</a></li>
          <li><a href="https://www.ommcofficial.org/REPS">New Brunswick, NJ (Rutgers Exposition in Problem Solving): Sun Apr 7 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/872592887817">New Brunswick, NJ: Sun Apr 7 at 4:00pm</a></li>
          <li>Raleigh, NC (Duke University): Tue Apr 9 at 6:00pm</li>
          <li>San Francisco, CA (Stratford Preparatory School, full): Thu Apr 11 at 6:30pm</li>
          <li>San Francisco, CA (Palo Alto High School): Fri Apr 12 at 7:30pm</li>
          <li><a href="https://www.stanfordmathtournament.com/competitions/smt-2024">San Francisco, CA (Stanford Math Tournament): Sat Apr 13 at 9:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/877564136957">San Francisco, CA (Sunnyvale): Sat Apr 13 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/881076973947">San Francisco, CA (Foster City): Sat Apr 13 at 7:30pm</a></li>
          <li><a href="https://berkeley.mt/events/bmmt-2024/">San Francisco, CA (Berkeley mini Math Tournament): Sun Apr 14 in morning</a></li>
          <li><a href="https://www.eventbrite.com/e/877563645487">San Francisco, CA (Fremont): Sun Apr 14 at 7:00pm</a></li>
          <li>Philadelphia, PA (Episcopal Academy): Tue Apr 16</li>
          <li><a href="https://form.jotform.com/240675020718150">Toronto: Sat Apr 20 at 10:30am</a></li>
          <li><a href="https://www.competitivekids.org/outsmarting-ai">Toronto (Brampton, with Competitive Kids STEM): Sat Apr 20 at 5:00pm</a></li>
          <li><a href="https://www.iitnt.org/events/l-e-t-s-talk-2024/">Dallas, TX (Indian Institute of Technology Alumni Association of North Texas, in Frisco): Sat Apr 27 at 11:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/881411223697">Dallas, TX (Richardson): Sat Apr 27 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/881557039837">Dallas, TX (Richardson): Sat Apr 27 at 5:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885011101027">Houston, TX (The Woodlands): Sun Apr 28 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/884987339957">Houston, TX (Katy): Sun Apr 28 at 3:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885712880067">Houston, TX (Sugar Land): Sun Apr 28 at 7:00pm (full)</a></li>
          <li><a href="https://www.eventbrite.com/e/883724994247">Austin, TX: Mon Apr 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/891285407647">San Diego, CA: Tue Apr 30 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/887840884987">Arcadia, CA: Wed May 1 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/884993327867">Irvine, CA: Thu May 2 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/893837501027">Portland, OR: Fri May 3 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885045413657">Seattle, WA: Sat May 4 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885043227117">Seattle, WA: Sat May 4 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885039927247">Seattle, WA: Sat May 4 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885098973857">Vancouver (White Rock): Sun May 5 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/894466863467">Minneapolis, MN: Tue May 7 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/895604937477">Salt Lake City, UT: Wed May 8 at 7:00pm</a></li>
          <li><a href="https://www.mathcon.org/">Chicago, IL (MathCON): Sat May 11</a></li>
          <li><a href="https://www.eventbrite.com/e/893844421727">Chicago, IL (Whitney Young Magnet High School): Sat May 11 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/897526785777">Howard County, MD: Sun May 12 at 2:30pm</a></li>
          <li><a href="https://www.mathcounts.org/programs/national-competition-schedule-glance">Washington, DC (MATHCOUNTS National Competition): Sun May 12, no talk, but at event</a></li>
          <li><a href="https://www.eventbrite.com/e/888363628527">Atlanta, GA: Tue May 14 at 7:00pm</a></li>
          <li><a href="https://www.scgssm.org/">Hartsville, SC (South Carolina Governor's School for Science and Mathematics): Wed May 15</a></li>
          <li><a href="https://www.eventbrite.com/e/885102775227">Charlotte, NC: Thu May 16 at 6:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885104871497">Raleigh, NC: Fri May 17 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885107800257">Great Falls (Fairfax County), VA: Sat May 18 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885118592537">Montgomery County, MD: Sat May 18 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/885112815257">Montgomery County, MD: Sat May 18 at 4:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/906177911527">West Windsor, NJ: Sun May 19 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/885123737927">Woodbridge, NJ: Sun May 19 at 2:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/905446844887">Woodbridge, NJ: Sun May 19 at 4:30pm</a></li>
          <li><a href="https://gifted.uconn.edu/wallace/">University of Connecticut (Wallace Symposium closing keynote): Tue May 21 at 1:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/900157945647">Syosset, NY: Tue May 21 at 7:00pm</a></li>
          <li>SUNY Stony Brook, Long Island, NY: Wed May 22 in evening (teachers)</li>
          <li>Great Neck, NY (Nassau County American Regions Math League team): Thu May 23</li>
          <li><a href="https://www.eventbrite.com/e/909999181047">Melbourne: Sun May 26 at 12:00pm, in Chinese</a></li>
          <li><a href={tUrl("VIC Melbourne")}>Melbourne: Sun May 26 at 7:00pm, in Chinese</a></li>
          <li><a href={tUrl("VIC Melbourne")}>Melbourne (Monash University, public lecture): Mon May 27 at 4:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/911657290497">Sydney: Tue May 28 at 7:00pm, in Chinese</a></li>
          <li><a href="https://events.humanitix.com/smri-public-lecture-using-maths-to-invent-solutions-to-large-scale-human-problems-just-in-time-to-survive-ai-with-po-shen-loh">Sydney (University of Sydney public lecture): Wed May 29 at 5:30pm</a></li>
          <li><a href="https://www.eventbrite.com.au/e/uniting-game-theory-maths-actors-to-build-human-intelligence-in-ai-age-tickets-904483132397">Sydney (University of New South Wales, for educators and university / graduate students): Thu May 30 at 1:00pm</a></li>
          <li><a href="https://events.humanitix.com/smri-high-school-student-workshop-unpacking-the-ai-survival-kit-with-po-shen-loh">Sydney (University of Sydney, for ambitious high school students with a creative mindset): Thu May 30 at 4:30pm</a></li>
          <li><a href="https://mathematical-research-institute.sydney.edu.au/event/po-shen-loh-sydney-2024/">Sydney (University of Sydney, seminar for mathematical scientists in academia or industry): Fri May 31 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/911653388827">Sydney: Fri May 31 at 7:00pm, in Chinese</a></li>
          <li><a href="https://event.ypo.org/event/10438a03-acf6-4d5c-ae86-feaec49f4100/">New York, NY (Young Presidents' Organization Global One Columbia Business School Academy): Wed Jun 26</a></li>
          <li><a href="https://www.mathmountains.org/">Jackson, WY (Math in the Mountains): Thu Jun 27 and Fri Jun 28</a></li>
          <li><a href="https://www.eventbrite.com/e/916424960727">San Francisco, CA (Palo Alto): Sat Jun 29 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/927994906747">San Francisco, CA (Sunnyvale): Sat Jun 29 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916440918457">San Francisco, CA (Milpitas): Sat Jun 29 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916431179327">San Francisco, CA (Pleasanton): Sun Jun 30 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/926312564817">San Francisco, CA (San Jose): Sun Jun 30 at 2:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/916326165227">San Francisco, CA (Fremont): Sun Jun 30 at 7:00pm</a></li>
          <li><a href="https://www.txst.edu/mathworks/camps/summer-math-camps-information/hsmc.html">San Marcos, TX (Mathworks Honors Summer Math Camp): Mon Jul 1</a></li>
          <li><a href="https://pgretreat.org/">Mt. Sterling, OH (PG Retreat): Tue Jul 2</a></li>
          <li><a href="https://rossprogram.org/">Columbus, OH (Ross Mathematics Program): Wed Jul 3</a></li>
          <li><a href="https://www.mathcamp.org/">Tacoma, WA (Canada/USA Mathcamp): Thu Jul 4</a></li>
          <li><a href="https://sumac.spcs.stanford.edu/">Stanford, CA (Stanford University Math Camp): Fri Jul 5</a></li>
          <li><a href="https://www.eventbrite.com/e/934699861447">Vancouver (University of British Columbia): Sat Jul 6 at 10:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/885100107247">Vancouver (West Vancouver): Sat Jul 6 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/927519996277">Vancouver (South Surrey): Sun Jul 7 at 10:00am</a></li>
          <li><a href="https://www.eventbrite.com/e/927524890917">Vancouver (South Surrey): Sun Jul 7 at 1:30pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910832593807">Monmouth County, NJ: Tue Jul 9 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910830186607">Basking Ridge, NJ: Wed Jul 10 at 7:00pm</a></li>
          <li><a href="https://www.eventbrite.com/e/910843105247">Bergen County, NJ: Thu Jul 11</a></li>
          <li><a href="https://www.eventbrite.com/e/910845933707">Suffolk County, NY: Fri Jul 12</a></li>
          <li><a href="https://www.imo2024.uk/">Bath, United Kingdom (International Mathematical Olympiad): Jul 14&ndash;21</a></li>
          <li><a href={tUrl("UK London")}>London: Wed Jul 17</a></li>
          <li><a href="https://imof.co/reunion/">United Kingdom (International Mathematical Olympiad Alumni Reunion, lead organizer): Jul 22&ndash;23</a></li>
        </OLStyled>

        <h2>Stay Notified</h2>

        <PStyled>
          To stay notified about future tour stops, please subscribe to our <a
            href="https://eepurl.com/gExYzT">email newsletter</a> which sends
          timely information about math education and contest deadlines, every
          two weeks. If your language preference is Chinese, please follow the
          WeChat Public Account: 罗博深数学.
        </PStyled>

        <PStyled>
          To follow future online live streams, please subscribe to our <a
            href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
            Challenge with Po-Shen Loh YouTube Channel</a>.
        </PStyled>

        <h2>Learn Math</h2>

        <PStyled>
          To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
        </PStyled>

      </Container>
      <Footer />
    </Styles>
  );
}
